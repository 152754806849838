<template>
  <div>
    <div v-for="order in orders" :key="order.id">
      <zoo-baza v-if="order.managers.length === 0 || order.managers[0].id === 1" :order="order"/>
      <zoo-market v-else :order="order" />
      <br />
      <div class="pagebreak"> </div>
    </div>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import ZooBaza from "../order/components/ZooBaza";
  import ZooMarket from "../order/components/ZooMarket";
  import moment from "moment";

  export default {
    name: 'OrdersPrint',
    components: {ZooMarket, ZooBaza},
    data: () => {
      return {
        dateString: '',
        orders: [],
      }
    },
    computed: {
      selectedOrderIds() {
        const grid = this.$store.getters.GET_GRID('logistic');
        if (grid && grid.selectedRows.length > 0) {
          return grid.selectedRows.map(order => order.id);
        }
        return [];
      },
    },
    async beforeMount() {
      try {
        const date = this.$store.getters.GET_LOGISTIC_DATE;
        const startDate = moment(date).utcOffset(0).startOf('day');
        const endDate = moment(startDate).endOf('day');
        this.dateString = moment(date).format('DD.MM.YYYY');
        let query = `order?join=forms&join=managers&join=positions&join=deliveryDates&join=paymentMethods` +
          `&filter=deliveryDates.date||$between||${startDate.format()},${endDate.format()}`
        if (this.selectedOrderIds.length > 0) {
          query += `&filter=id||$in||${this.selectedOrderIds.join(',')}`;
        }
        const orders = await APIService.get(query);
        this.orders = orders.filter(order => {
          order.positions = order.positions.filter(position => !position.removed);
          return order;
        });
        new Promise(resolve => setTimeout(() => {
          window.print();
          window.onafterprint = () => {
            this.$router.back();
          };
          resolve();
        }, 400));
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
